$(document).on("turbolinks:load", function () {
  $.$behavior.to('.customers-index, .platforms-index, .contacts-index, .dashboard-saas-index, .customer_success-general-index, .customer_success-contracts-index, .financial-accounts-index', function () {

    $('.check_boxes').on('change', function () {
      const isActive = $(this).is(":checked");
      if (isActive) {
        $(this).parent().addClass('container-active');
      } else {
        $(this).parent().removeClass('container-active');
      }
    });

    function checkBoxesIsChecked() {
      $('.check_boxes').each(function () {
        const isActive = $(this).is(":checked");
        if(isActive){
          $(this).parent().addClass('container-active');
        } else {
          $(this).parent().removeClass('container-active');
        }
      });
    }

    $(document).ready(function () {
      checkBoxesIsChecked()
    });

  });
});