$(document).on("turbolinks:load", function () {
  $.$behavior.to('.platforms-show, .platforms-edit', function () {
    $('a.collapse-link').on('click', function(){
      const $inputDirection = $(this).data('value') == 'project_report' ? $("#platform_orders_attributes_0_project_report") : $("#platform_orders_attributes_1_client_report")
      $('html, body').animate({
          scrollTop: $inputDirection.offset().top
      }, 2000, function() {
          $("#platform_orders_attributes_0_project_report").focus();
      });
    });

    $(document).ready(function() {
      $('a[data-toggle="collapse"]').on('click', function () {
        const nextLine = $(this).data('parent')
        if($(`#${nextLine}`).hasClass('in')) {
          $(`#${nextLine}`).slideToggle(200);
        }
      });
    });
  });
});

$(document).on("turbolinks:load", function () {
  $.$behavior.to("#platform_filter_type", function () {
    const $inputGeneric = $("#platform_attributes");
    const $inputType = $("#platform_filter_type");

    $inputType.on("change", function () {
      const val = $(this).val();
      const placeHolder = $(this).find("option:selected").text();

      $inputGeneric.attr("placeholder", placeHolder);
      $inputGeneric.attr("name", `filter[${val}]`);
    });

    function queryObj() {
      var result = {}, keyValuePairs = location.search.slice(1).split("&");
      keyValuePairs.forEach(function(keyValuePair) {
          keyValuePair = keyValuePair.split('=');
          result[decodeURIComponent(keyValuePair[0])] = decodeURIComponent(keyValuePair[1]) || '';
      });
      return result;
    }

    var valueFiltred = queryObj();
    setGenericInput();
    function setGenericInput() {
      const optionsAvaibles = ["filter[name]","filter[uid]","filter[url]","filter[subdomain]"]
      optionsAvaibles.map(function(index) {
        if(valueFiltred.hasOwnProperty(index) && valueFiltred[index]){
          $inputGeneric.attr("name", `${index}`);
          $inputGeneric.val(valueFiltred[index].replace("+", " "));
          res = index.match(/(?<=\[)[^[\]]+(?=\])/g);
          $inputType.val(res[0])
        }
      });

      if(!$inputGeneric.val()) {
        $inputType.val('name')
        $inputGeneric.attr("name", "filter[name]");
        $inputGeneric.attr("placeholder", "Nome");
      }
    }

  });
});
