/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/

$(document).on("turbolinks:load", function() {
  $("#negotiation_price_per_student, #negotiation_price_per_extra_student, #negotiation_price_per_free_student, #negotiation_price_per_extra_free_student, #negotiation_price_per_rs_student, #negotiation_price_per_extra_rs_student").maskMoney({
    prefix: "R$ ",
    decimal: ",",
    thousands: "."
  });
  $("#negotiation_price_per_student, #negotiation_price_per_extra_student, #negotiation_price_per_free_student, #negotiation_price_per_extra_free_student, #negotiation_price_per_rs_student, #negotiation_price_per_extra_rs_student").maskMoney("mask");

  $('.negotiation-license-product-select').bootstrapDualListbox({
    showFilterInputs: true
  });

  $('#negotiation_observation').markdown({
    autofocus: true,
    height: 300,
    resize: 'vertical',
    iconlibrary: 'fa'
  });

  return $('#negotiation_explanation_about_billing').markdown({
    autofocus: true,
    height: 300,
    resize: 'vertical',
    iconlibrary: 'fa'
  });
});
