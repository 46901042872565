$(document).on("turbolinks:load", function () {
  $.$behavior.to(".platforms-billings-new, .platforms-billings-create", function () {
    const $platformId = $("#billing_platform_id");
    const $date = $("#billing_date");

    $(document).ready(function () {
      fetchOrdersToCharge($platformId.val(), $date.val());
    });

    $date.on("change", function () {
      fetchOrdersToCharge($platformId.val(), this.value);
    });

    function fetchOrdersToCharge(platform_id, date) {
      $.ajax({
        url: '/common/orders_to_charge',
        data: { platform_id: platform_id, date: date }
      }).done(function (response) {
        $('#orders_to_charge').html(response);
      });
    }
  });
});
