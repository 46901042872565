$(document).on("turbolinks:load", function () {
  $.$behavior.to(".customers-edit", function () {

    const $fieldCPF = $("#customer_cpf");
    const $fieldCNPJ = $("#customer_cnpj");
    const $fieldIsIndividual = $("#customer_is_individual");
    const $fieldCep = $("#customer_zipcode");

    $("#zipcode-error").hide();

    $fieldIsIndividual.on("change", function () {
      profileToggle($fieldIsIndividual.is(':checked'));
    });

    $("#customer_country").on("change", function () {
      updateCountryFields();
    });

    $("#customer_zipcode").blur(function () {
      if ($("#customer_country").val() == "BR") {
        var cepValue = $(this).val().replace(/\D/g, "");
        if (cepValue.length == 8) {
          searchCep(cepValue);
        }
      }
    });

    function searchCep(cepValue) {
      var zipCode = cepValue;
      request_zipcode(zipCode);
      event.preventDefault();
    }

    function request_zipcode(zipCode) {
      var url = `https://viacep.com.br/ws/${zipCode}/json/`

      if (zipCode) {
        $.getJSON(url).done(function (data) {
          if(data.erro) {
            $fieldCep.addClass('error-field');
          } else {
            setAddress(data);
            $fieldCep.removeClass('error-field');
          }
        }).fail(function () {
          $fieldCep.addClass('error-field');
        });
      } else {
        $fieldCep.focus();
      }
    }

    function setAddress(data) {
      $("#customer_street").val(data.logradouro);
      $("#customer_state").val(data.uf).trigger('change');
      $("#customer_city").val(data.localidade);
      $("#customer_neighborhood").val(data.bairro);
      $("#customer_ibge").val(data.ibge);
    }

    function updateCountryFields() {
      if ($("#customer_country").val() == 'BR') return;

      var path = "/country_info?country=" + $("#customer_country").val();
      $.ajax({
        url: path
      }).done(function (html) {
        $('#customer_continent_accessor').val(html.continent);
        var output = '';
        $('#customer_state').empty().append(function () {
          html.states.forEach(function (item) {
            output += "<option>" + item + "</option>"
          });
          return output;
        });

        if ($.inArray($("#customer_persisted_state").val(), html.states))
          $("#customer_state").val($("#customer_persisted_state").val());
      });
    }

    function profileToggle(is_individual) {
      $fieldCPF.prop('disabled', !is_individual);
      $fieldCNPJ.prop('disabled', is_individual);
    }

    $("#customer_phone").mask('99999999Z', {
      translation: {
        'Z': {
          pattern: /[0-9]/,
          optional: true
        }
      }
    });
    $fieldCNPJ.mask("00.000.000/0000-00", {
      reverse: true
    });
    $fieldCPF.mask("000.000.000-00", {
      reverse: true
    });

    $(document).ready(function () {
      updateCountryFields();
      profileToggle($fieldIsIndividual.is(':checked'));
    });
  });
});
