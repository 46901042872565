$(document).on("turbolinks:load", function() {
  $.$behavior.to('.activities', function () {

    const DEFAULT_ACTION_OPT='ligacao';

    $("#activity_kind").on('change', function () {
      setActionsFields();
    });

    function getOptionsFor(obj) {
      var options = ''
      if (!obj) { return '' }
      if (Array.isArray(obj)) {
        obj.forEach(function(k) {
          options += "<option value=\""+k[1]+"\">" + k[0] + "</option>"
        });
      }
      else {
        Object.keys(obj).forEach(function(k) {
          options += "<option value=\""+k+"\">" + obj[k].name + "</option>"
        });
      }
      return options;
    }

    function setActionsFields() {
      var kind = $('#activity_kind').val()
      if (!kind) return ''
      $('#activity_action').empty().append(function() {
        return getOptionsFor(categorization_hash[kind].values)
      });
      $('#activity_action').val(DEFAULT_ACTION_OPT).change();
    }

    $(document).ready(function() {
      setActionsFields();
    });

    onchange: 'setActionsFields()'
  });
});
