$(document).on("turbolinks:load", function () {
  $.$behavior.to(".platforms-orders-new, .platforms-orders-create, .platforms-orders-edit, .platforms-orders-update, .platforms-orders-replace", function () {
    const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
    const $planId = $("#order_plan_id");
    const $orderPrice = $("#order_price");
    const $orderCustom = $("#order_custom");

    $(document).ready(function () {
      fetchOrderPlan($planId.val());
      toggleOrderPrice($orderCustom.is(':checked'));
      updateLicenses();
      updatePricesTable();
    });

    $planId.on("change", function () {
      fetchOrderPlan(this.value);
    });

    $orderCustom.on("change", function () {
      toggleOrderPrice($(this).is(":checked"));
    });

    $(document).on('change', '[id^=order_licenses_attributes_][id$=_product_id]', function () {
      fetchLicenseProduct(this);
    });

    $(document).on('change', '[id^=order_licenses_attributes_][id$=_custom]', function () {
      toggleLicenseFields(this);
    });

    $(document).on('change', function () {
      $('[id^=order_licenses_attributes_][id$=_custom]').each(function(){
        toggleLicenseFields(this);
      });
      updatePricesTable();

      $('.mask_money').each(function() {
        $(this).maskMoney({
          allowZero: true,
          prefix: "R$ ",
          decimal: ",",
          thousands: "."
        }).maskMoney('mask');
      });
    });

    function fetchOrderPlan(plan_id) {
      $.ajax({
        url: '/common/plan',
        data: { plan_id: plan_id, target: 'plan' }
      }).done(function (response) {
        updateOrderPrice(response);
        updatePlanLicensesTable(plan_id);
        updatePricesTable();
      });
    }

    function updateOrderPrice(response) {
      if (response && !$orderCustom.is(':checked')) {
        price = response['price'];
      } else if ($orderPrice.val()) {
        price = $orderPrice.val().replace(/[^0-9]+/g,"");
      } else {
        price = 0
      }
      $orderPrice.val(formatter.format((price / 100).toFixed(2)))
    }

    function updatePlanLicensesTable(plan_id) {
      $.ajax({
        url: '/common/plan',
        data: { plan_id: plan_id, target: 'licenses' }
      }).done(function (response) {
        $('#order_plan_licenses').html(response);
      });
    }

    function toggleOrderPrice(custom) {
      $orderPrice.prop('disabled', !custom);

      if (!$orderCustom.is(':checked')) {
        fetchOrderPlan($planId.val());
      }
    }

    function updateLicenses() {
      $('[id^=order_licenses_attributes_][id$=_product_id]').each(function(){
        fetchLicenseProduct(this);
      });
      $('[id^=order_licenses_attributes_][id$=_custom]').each(function(){
        toggleLicenseFields(this);
      });
    }

    function fetchLicenseProduct(obj) {
      const productId = $(obj).val();
      const licenseId = $(obj).attr('id').replace('order_licenses_attributes_', '').replace('_product_id', '');
      $.ajax({
        url: '/common/product',
        data: { product_id: productId }
      }).done(function (response) {
        updateLicenseFields(licenseId, response);
        updatePricesTable();
      });
    }

    function updateLicenseFields(licenseId, response) {
      var licenseCustom = $('#order_licenses_attributes_' + licenseId + '_custom').is(':checked');
      var licensePrice = $('#order_licenses_attributes_' + licenseId + '_price');
      var licenseHardFranchise = $('#order_licenses_attributes_' + licenseId + '_hard_franchise');
      var licenseFranchiseUsersLimit = $('#order_licenses_attributes_' + licenseId + '_franchise_limit');
      var licenseFranchiseSurplusValue = $('#order_licenses_attributes_' + licenseId + '_franchise_surplus_value');

      if (response && !licenseCustom) {
        var price = response['price'];
        var hardFranchise = response['hard_franchise'];
        var franchiseUsersLimit = response['franchise_limit'];
        var franchiseSurplusValue = response['franchise_surplus_value'];
      } else if (licensePrice.val()
                || licenseFranchiseUsersLimit.val()
                || licenseFranchiseSurplusValue.val()) {
        var price = licensePrice.val().replace(/[^0-9]+/g,"");
        var hardFranchise = licenseHardFranchise.is(':checked');
        var franchiseUsersLimit = licenseFranchiseUsersLimit.val();
        var franchiseSurplusValue = licenseFranchiseSurplusValue.val().replace(/[^0-9]+/g,"");
        debugger;
      } else {
        var price = 0;
        var hardFranchise = false;
        var franchiseUsersLimit = 0;
        var franchiseSurplusValue = null;
      }
      licensePrice.val(formatter.format((price / 100).toFixed(2)));
      licenseHardFranchise.prop('checked', hardFranchise);
      licenseFranchiseUsersLimit.val(franchiseUsersLimit);
      if (franchiseSurplusValue) {
        licenseFranchiseSurplusValue.val(formatter.format((franchiseSurplusValue / 100).toFixed(2)));
      } else {
        licenseFranchiseSurplusValue.val(null);
      }
    }

    function toggleLicenseFields(obj) {
      const licenseId = $(obj).attr('id').replace('order_licenses_attributes_', '').replace('_custom', '');
      const licenseCustom = $('#order_licenses_attributes_' + licenseId + '_custom').is(':checked');
      $('#order_licenses_attributes_' + licenseId + '_price').prop('disabled', !licenseCustom);
      $('#order_licenses_attributes_' + licenseId + '_hard_franchise').prop('disabled', !licenseCustom);
      $('#order_licenses_attributes_' + licenseId + '_franchise_limit').prop('disabled', !licenseCustom);
      $('#order_licenses_attributes_' + licenseId + '_franchise_surplus_value').prop('disabled', !licenseCustom);

      if (!licenseCustom) {
        fetchLicenseProduct($('#order_licenses_attributes_' + licenseId + '_product_id'));
      }
    }

    function updatePricesTable() {
      var disabledInputs = $('form').find(':input:disabled');
      disabledInputs.prop('disabled', false);
      var serialized = $('form').serialize();
      disabledInputs.prop('disabled', true);

      orderDivId = $('[id^=edit_order_]').attr('id');
      orderId = ''
      if (orderDivId) {
        orderId = orderDivId.replace('edit_order_', '');
      }
      $.ajax({
        url: '/common/prices_preview',
        data: serialized + '&' + $.param({ id: orderId})
      }).done(function(response){
        $('#order_prices_table').html(response);
      });
    }
  });
});
