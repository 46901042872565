$(document).on("turbolinks:load", function () {
  $.$behavior.to('.edools-proposal-show, .edools-proposal-update', function () {
    $.$behavior.to('.form-inputs', function () {
      if ($('#customer_cpf').val().length > 0) {
        showFis();
      } else {
        showJur();
      }
      $('.btn-type').on('click', function () {
        const valueType = $(this).val();
        if (valueType ==  'true') {
          showFis();
        } else {
          showJur();
        }
      });

      function showFis() {
        $('.pesFis').show();
        $('.pesJur').hide();
        $('.btn-fis').attr('checked','checked');
        $('.btn-jur').attr('checked','');
        $('#customer_is_individual').val(true);
      }

      function showJur() {
        $('.pesFis').hide();
        $('.pesJur').show();
        $('.btn-jur').attr('checked','checked');
        $('.btn-fis').attr('checked','');
        $('#customer_is_individual').val(false);
      }

      $("#customer_cnpj").mask("00.000.000/0000-00", { reverse: true });
      $("#customer_cpf").mask("000.000.000-00", { reverse: true });
      $("#customer_zipcode").mask("00.000-000", { reverse: true });

      $("#customer_zipcode").blur(function () {
        var cepValue = $(this).val().replace(/\D/g, "");
        if (cepValue.length == 8) {
          searchCep(cepValue);
        }
      });

      function searchCep(cepValue) {
        var element = $("#customer_zipcode");
        var cepError = $('#zipcode-error');
        var zipCode = cepValue;
        cepError.hide();
        element.removeClass('zipcode-error');
        request_zipcode(zipCode, element, cepError);
        event.preventDefault();
      }

      function request_zipcode(zipCode, element, cepError) {
        var url = `https://viacep.com.br/ws/${zipCode}/json/`

        if (zipCode) {
          var field = $(this);
          field.attr('disabled', 'disabled');
          $.getJSON(url).done(function (data) {
            setAddress(data);
          }).fail(function () {
            element.addClass('zipcode-error');
          });
        } else {
          element.focus();
        }
      }

      function setAddress(data) {
        $("#customer_street").val(data.logradouro);
        $("#customer_state").val(data.uf).trigger('change');
        $("#customer_city").val(data.localidade);
        $("#customer_neighborhood").val(data.bairro);
        $("#customer_ibge").val(data.ibge);
      }

      $("#bt_submit").attr("disabled", true);

      function validForm() {
        if ($('[id ^= "edit_customer_"]').valid()) {
          $('#bt_submit').prop('disabled', false);
        } else {
          $('#bt_submit').prop('disabled', 'disabled');
        }
      }

      $('input').on('blur', function () {
        validForm();
      });

      $('#confirm').on('click', function () {
        validForm();
      });
    });

    $('[id^=edit_customer_]').validate({
      rules: {
        subscriber_name: {
          required: true,
          minlength: 5
        },
        'customer[corporate_name]': {
          required: true,
          minlength: 5,
          lettersonly: true
        },
        'customer[email]': {
          required: true,
          email: true
        },
        'customer[email_financial]': {
          required: false,
          email: true
        },
        'customer[ddd]': {
          required: true,
          number: true,
          minlength: 2,
          maxlength: 2
        },
        'customer[phone]': {
          required: true,
          number: true,
          minlength: 8,
          maxlength: 9
        },
        'customer[name]': {
          required: true,
          minlength: 5
        },
        'customer[cnpj]': {
          required: true,
          cnpjvalid: true
        },
        'customer[cpf]': {
          required: true,
          cpfvalid: true
        },
        'customer[street]': {
          required: true,
          minlength: 5
        },
        'customer[street_number]': {
          required: true,
          number: true
        },
        'customer[neighborhood]': {
          required: true,
          minlength: 3
        },
        'customer[city]': {
          required: true,
          minlength: 3
        },
        'customer[state]': {
          required: true
        },
        'customer[zipcode]': {
          required: true,
          minlength: 8,
          zipcodevalid: true
        },
        signup_confirm: {
          required: true
        }
      }
    });

    jQuery.validator.addMethod("lettersonly", function (value, element) {
      return this.optional(element) || /^[a-z0-9 ,.'-]+$/i.test(value);
    }, "Apenas letras, números e pontuações ( ,.'-)");

    jQuery.validator.addMethod("zipcodevalid", function (value, element) {
      value = jQuery.trim(value);
      return value.replace(/\D+/g, '').length == 8;
    }, "Informe um CEP válido");

    jQuery.validator.addMethod("cpfvalid", function (value, element) {
      value = jQuery.trim(value);

      cpf = value.replace(/\D+/g, '');

      while (cpf.length < 11) cpf = "0" + cpf;
      var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
      var a = [];
      var b = new Number;
      var c = 11;
      for (i = 0; i < 11; i++) {
        a[i] = cpf.charAt(i);
        if (i < 9) b += (a[i] * --c);
      }
      if ((x = b % 11) < 2) {
        a[9] = 0
      } else {
        a[9] = 11 - x
      }
      b = 0;
      c = 11;
      for (y = 0; y < 10; y++) b += (a[y] * c--);
      if ((x = b % 11) < 2) {
        a[10] = 0;
      } else {
        a[10] = 11 - x;
      }

      var retorno = true;
      if ((cpf.charAt(9) != a[9]) || (cpf.charAt(10) != a[10]) || cpf.match(expReg)) retorno = false;

      return this.optional(element) || retorno;

    }, "Informe um CPF válido");

    jQuery.validator.addMethod("cnpjvalid", function (value, element) {

      var numeros, digitos, soma, i, resultado, pos, tamanho, digitos_iguais;
      if (value.length == 0) {
        return false;
      }

      value = value.replace(/\D+/g, '');
      digitos_iguais = 1;

      for (i = 0; i < value.length - 1; i++)
        if (value.charAt(i) != value.charAt(i + 1)) {
          digitos_iguais = 0;
          break;
        }
      if (digitos_iguais)
        return false;

      tamanho = value.length - 2;
      numeros = value.substring(0, tamanho);
      digitos = value.substring(tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)) {
        return false;
      }
      tamanho = tamanho + 1;
      numeros = value.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
          pos = 9;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

      return (resultado == digitos.charAt(1));

    }, "Informe um CNPJ válido");
  });
});
