/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/

$(document).on("turbolinks:load", function() {
  $(".new_product .product_price, .edit_product .product_price").hide();
  $(".new_product .product_franchise_range, .edit_product .product_franchise_range").hide();

  $('#product_kind, #license_kind').change(function() {
    let kind = '';
    $('#product_kind option:selected, #license_kind option:selected').each(function() {
      return kind += $(this).val() + '';
    });

    switch (kind) {
      case '':
        $(".new_product .product_price, .edit_product .product_price, .edit_license .license_price").hide();
        return $(".new_product .product_franchise_range, .edit_product .product_franchise_range, .edit_license .license_franchise_range").hide();
      case 'fixed_per_customer':
        $(".new_product .product_price, .edit_product .product_price, .edit_license .license_price").show();
        return $(".new_product .product_franchise_range, .edit_product .product_franchise_range, .edit_license .license_franchise_range").hide();
      case 'fixed_per_school':
        $(".new_product .product_price, .edit_product .product_price, .edit_license .license_price").show();
        return $(".new_product .product_franchise_range, .edit_product .product_franchise_range, .edit_license .license_franchise_range").hide();
      case 'student_franchise':
        $(".new_product .product_price, .edit_product .product_price, .edit_license .license_price").hide();
        return $(".new_product .product_franchise_range, .edit_product .product_franchise_range, .edit_license .license_franchise_range").show();
    }
  });

  return $('#product_franchise_range, #license_franchise_range').markdown({
    autofocus: false,
    height: 200,
    iconlibrary: 'fa',
    hiddenButtons: ['cmdBold', 'cmdItalic', 'cmdHeading', 'cmdUrl',
      'cmdImage', 'cmdList', 'cmdListO', 'cmdCode', 'cmdQuote', 'cmdPreview'],
    fullscreen: false
  });
});
