// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/

$(document).on('turbolinks:load', function () {
  // $('#bt_subbmit').prop 'disabled', true
  // $('#confirm').click ->
  //   if $(this).is(':checked')
  //     $('#bt_subbmit').removeAttr 'disabled'
  //   else
  //     $('#bt_subbmit').attr 'disabled', 'disabled'
  //   return
  $.$behavior.to(".subscribes-index", function () {
    $(document).ready(function () {
      showJur();
    });
    const $fieldName = $('#customer_name');
    const $fieldCnpj = $('#customer_cnpj');
    const $contentCnpj = $('.customer_cnpj, .customer_corporate_name');
    const $fieldCpf = $('#customer_cpf');
    const $fieldCorporate = $('#customer_corporate_name');
    const $fieldIsIndividual = $('#customer_is_individual');

    $('#subscribe_choose').click(function () {
      if ($(this).is(':checked')) {
        showFis();
      } else {
        showJur();
      }
    });

    function showFis() {
      $fieldCnpj.hide();
      $fieldCnpj.val('');
      $contentCnpj.hide();
      $fieldCorporate.attr('disabled', 'true');
      $fieldCorporate.hide();
      $fieldCorporate.val('');
      $fieldCpf.val('');
      $fieldCpf.show();
      $fieldIsIndividual.val('true');
    }

    function showJur() {
      $contentCnpj.show();
      $fieldName.val('');
      $fieldCorporate.removeAttr('disabled');
      $fieldCorporate.show();
      $fieldCorporate.attr('placeholder', 'Razão social (obrigatório)');
      $fieldCnpj.show();
      $fieldCpf.val('');
      $fieldCpf.hide();
      $fieldIsIndividual.val('false');
    }

    $("#bt_submit").attr("disabled", true);

    function validForm() {
      console.log($('#new_customer').valid())
      if ($('#new_customer').valid()) {
        $('#bt_submit').prop('disabled', false);
      } else {
        $('#bt_submit').prop('disabled', 'disabled');
      }
    }

    $('input').on('blur', function () {
      if($(this).valid()) {
        validForm() ;
      }
    });

    $('#new_customer').validate({
      rules: {
        subscriber_name: {
          required: true,
          minlength: 5
        },
        'customer[email]': {
          required: true,
          email: true
        },
        'customer[ddd]': {
          required: true,
          number: true,
          minlength: 2,
          maxlength: 2
        },
        'customer[phone]': {
          required: true,
          number: true,
          minlength: 8,
          maxlength: 9
        },
        'customer[name]': {
          required: true,
          minlength: 5
        },
        'customer[cnpj]': {
          required: true,
          number: true,
          minlength: 11
        },
        'customer[street]': {
          required: true,
          minlength: 5
        },
        'customer[street_number]': {
          required: true,
          number: true
        },
        'customer[neighborhood]': {
          required: true,
          minlength: 3
        },
        'customer[city]': {
          required: true,
          minlength: 3
        },
        'customer[state]': {
          required: true
        },
        'customer[zipcode]': {
          required: true,
          number: true,
          minlength: 8,
          maxlength: 8
        },
        signup_confirm: {
          required: true
        }
      }
    });
  });

  $.$behavior.to(".edools-subscription-index, .edools-subscription-create", function () {

    function validForm() {
      if ($('#new_customer').valid()) {
        $('#bt_submit').prop('disabled', false);
      } else {
        $('#bt_submit').prop('disabled', 'disabled');
      }
    }
    
    $('input').on('blur', function () {
      if($(this).valid()) {
        validForm() ;
      }
    });

    $('#bt_submit').on('click', function (event) {
      var response = grecaptcha.getResponse();
      validForm();
      if(response == 0) {
        window.toastr.warning('Necessário preencher o captcha')
        event.preventDefault();
      }
    });

    jQuery.validator.addMethod("lettersonly", function(value, element) {
      return this.optional(element) || /^[A-Za-z ]+$/i.test(value);
    }, "Por favor, foneça um nome com apenas letras"); 

    $('#new_customer').validate({
      rules: {
        'customer[email]': {
          required: true,
          email: true
        },
        'customer[name]': {
          required: true,
          minlength: 5,
          lettersonly: true
        },
        'customer[ddd]': {
          required: true,
          minlength: 2,
          maxlength: 3
        },
        'customer[phone]': {
          required: true,
          minlength: 8,
          maxlength: 9
        },
        'school_name': {
          required: true,
          minlength: 2,
          maxlength: 100
        },
        'customer[password]':{
          required: true,
          minlength: 6
        },
        signup_confirm: {
          required: true
        }
      }
    });

    $("#customer_phone").mask('99999999Z', {
      translation: {
        'Z': {
          pattern: /[0-9]/,
          optional: true
        }
      }
    });

  });
});