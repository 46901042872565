// Adyen
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

$(document).on("turbolinks:load", function() {
  $.$behavior.to('.edools-proposal-show, .edools-proposal-update', function () {
    $.$behavior.to('#card-container', function () {
      require('stylesheet/sparkpay_edools');

      const axios = require('axios');
      // GLOBAL
      const axiosInstance = axios.create();

      // ADYEN
      const config = {
        edools: {
          brands: ['mc', 'visa', 'hipercard', 'elo', 'amex'],
          hasHolderName: true,
          holderNameRequired: true,
          styles: {
            base: {
              color: '#525252'
            },
          },
          placeholders: {
            encryptedCardNumber: '____ ____ ____ ____',
            encryptedExpiryDate: 'MM/AA',
            encryptedSecurityCode: '123',
            holderName: 'João A. Silva'
          },
        }
      };

      const def_form_option = function() {
        switch (process.env.RAILS_ENV) {
        case 'production':
          return {
            environment: 'live-us',
            originDomain: 'https://heimdall.herospark.com',
            sparkpayDomain: 'https://sparkpay.eadbox.com/domain',
            logData: false
          };
          break;
        case 'staging':
          return {
            environment: 'test',
            originDomain: 'https://heimdall-staging.herospark.com',
            sparkpayDomain: 'https://sparkpay.beta.eadbox.com/domain',
            logData: false
          };
          break;
        default:
          return {
            environment: 'test',
            originDomain: 'http://' + process.env.DOMAIN_NAME,
            sparkpayDomain: 'http://sparkpay:3004/domain',
            logData: true
          };
          break;
        }
      };
      const form_options = def_form_option();

      const adyen = {
        cardData: {
          encryptedCardNumber: null,
          encryptedExpiryMonth: null,
          encryptedExpiryYear: null,
          encryptedSecurityCode: null,
          holderName: null,
        },
        submit: null,
        success: false,
        createCardForm: async ({ formType, containerId, environment, locale, originDomain, onLoad, onBrand, onChange, onCardNumberInput }) => {
          const res = await axiosInstance.post(form_options['sparkpayDomain'], { domain_origin: originDomain });
          const originKey = res.data.key;

          // Checkout instance
          const checkout = new AdyenCheckout({
            environment,
            locale,
            originKey,
          });

          // Form Mounting
          const adyenForm = checkout
            .create('card', {
              ...config[formType],
              onBrand: (data) => {
                onBrand(data.brand);
              },
              onChange: state => {
                const {
                  encryptedCardNumber,
                  encryptedExpiryMonth,
                  encryptedExpiryYear,
                  encryptedSecurityCode,
                  holderName,
                } = state.data.paymentMethod;

                adyen.encryptedCardNumber = encryptedCardNumber;
                adyen.encryptedExpiryMonth = encryptedExpiryMonth;
                adyen.encryptedExpiryYear = encryptedExpiryYear;
                adyen.encryptedSecurityCode = encryptedSecurityCode;
                adyen.holderName = holderName;

                onChange({
                  encryptedCardNumber,
                  encryptedExpiryMonth,
                  encryptedExpiryYear,
                  encryptedSecurityCode,
                  holderName,
                });

                if (encryptedCardNumber && encryptedExpiryMonth && encryptedExpiryYear && encryptedSecurityCode && holderName) {
                  adyen.success = true;
                } else {
                  adyen.success = false;
                }
              },
              onFocus: data => {
                adyen.addFocusClass(data);
              },
              onBlur: data => {
                adyen.addFocusClass(data, 'remove');
              },
              onBinValue: data => onCardNumberInput(data.binValue),
              onError: data => {
                if (data.error) {
                  adyen.success = false;
                }
              }
            })
            .mount(`#${containerId}`);

          // Binding the submit function
          adyen.submit = adyenForm.submit;
        },
        addFocusClass: (data, action = 'add') => {

          let fieldType = data.fieldType.replace('encrypted', '');
          fieldType = fieldType[0].toLowerCase() + fieldType.substring(1);

          const focusClass = 'adyen-checkout__field--focused';
          const field = document.getElementsByClassName(`adyen-checkout__field--${fieldType}`)[0];

          switch (action) {
            case 'remove':
              field.classList.remove(focusClass);
              break;

            default:
              field.classList.add(focusClass);
              break;
          }
        }
      };

      adyen.createCardForm({
        environment: form_options['environment'], // Test or Real payment creations
        originDomain: form_options['originDomain'], // Dynamic originKey generation
        formType: 'edools', // Style of the form
        containerId: 'card-container', // The container DOM element
        locale: 'pt-BR', // Translation of labels and validation messages
        onBrand: brand => null, // console.log(brand)
        onChange: cardData => (form_options['logData'] ? console.log(cardData) : null),
        onCardNumberInput: cardNumber => null, // console.log(cardNumber)
      });

      document.getElementById('bt_submit').addEventListener('click', () => {
        adyen.submit();

        if (adyen.success) {
          $('#brand')[0].value = adyen.brand;
          $('#encrypted_card_number')[0].value = adyen.encryptedCardNumber;
          $('#encrypted_expiry_month')[0].value = adyen.encryptedExpiryMonth;
          $('#encrypted_expiry_year')[0].value = adyen.encryptedExpiryYear;
          $('#encrypted_security_code')[0].value = adyen.encryptedSecurityCode;
          $('#holder_name')[0].value = adyen.holderName;
        }
      });
    });
  });
});
