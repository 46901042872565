/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/

$(document).on("turbolinks:load", function() {
  $('.datepicker_date').datepicker({
    format: 'dd/mm/yyyy',
    autoclose: true,
    todayBtn: true,
    todayHighlight: true,
    language: 'pt-BR',
    defaultViewDate: 'today'
  });

  $('.datepicker_month').datepicker({
    format: 'mm/yyyy',
    autoclose: true,
    todayBtn: true,
    todayHighlight: true,
    language: 'pt-BR',
    viewMode: 'months',
    minViewMode: 'months'
  });

  $('.dropdown_select').select2({
    language: 'pt-BR',
    placeholder: 'TODOS',
    allowClear: true,
    width: '100%'
  });

  $('.dropdown_select_blank').select2({
    language: 'pt-BR',
    placeholder: 'Nenhum',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true
  });

  $('.dropdown_select_noclear').select2({
    language: 'pt-BR',
    placeholder: 'Nenhum',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true
  });

  $('.mask_money').each(function() {
    $(this).maskMoney({
      allowZero: true,
      prefix: "R$ ",
      decimal: ",",
      thousands: "."
    }).maskMoney('mask');
  });

  $('.mask_money_empty').each(function() {
    $(this).maskMoney({
      allowEmpty: true,
      prefix: "R$ ",
      decimal: ",",
      thousands: "."
    }).maskMoney('mask');
  });

  $.$behavior.to(".dropdown-toggle", function () {
    $(".dropdown-toggle").dropdown();
  });

  $(document).on("turbolinks:load", function() {
    $.$behavior.to(".dropdown-toggle", function () {
      $(".dropdown-toggle").dropdown();
    });

    $('#btn-block').tooltip().attr('data-original-title', 'Desativar conta');
    $('#btn-unblock').tooltip().attr('data-original-title', 'Reativar conta');
    $('#btn-find').tooltip().attr('data-original-title', 'Reativar conta');

  });

  // Collapse ibox function
  $('.collapse-panel-link').on('click', function () {
      var ibox = $(this).closest('div.panel');
      var button = $(this).find('i');
      var content = ibox.children('.panel-body');
      content.slideToggle(200);
      button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
      ibox.toggleClass('').toggleClass('border-bottom');
      setTimeout(function () {
          ibox.resize();
          ibox.find('[id^=map-]').resize();
      }, 50);
  });
});

window.toggleAdvancedForm = function(div_params) {
  $(div_params).toggle('fast', 'swing');
};

window.toggleNps = function(survey) {
  $('.' + survey).each(function() {
    $(this).toggle('fast', 'swing');
  });
};

$(document).ready(function() {
  $('a[disabled=disabled]').click(function(event){
    event.preventDefault();
  });
});
