$(document).on("turbolinks:load", function () {
  $.$behavior.to(".financial-audit-index", function () {
    const $category = $("#filter_category");
    const $kind = $("#filter_kind");
    const $kindAccessor = $('#filter_kind_accessor');

    $(document).ready(function () {
      updateKindField($category.val());
    });

    $category.on("change", function () {
      updateKindField(this.value);
    });

    $kind.on("change", function () {
      updateFilterFields($category.val(), this.value);
    });

    function updateKindField(category_value) {
      $.ajax({
        url: '/common/report',
        data: { list_path: ['audit', category_value], list_field: 'kinds' }
      }).done(function (response) {
        var output = '';
        $kind.empty().append(function () {
          response.forEach(function (name_kind) {
            output += '<option value=' + name_kind[1] + '>' + name_kind[0] + '</option>'
          });
          return output;
        });

        var kinds = response.map(function (name_kind) {
          return name_kind[1]
        });
        if($.inArray($kindAccessor.val(), kinds) != -1) {
          $kind.val($kindAccessor.val());
        }

        updateFilterFields(category_value, $kind.val());
      });
    }

    function updateFilterFields(category_value, kind_value) {
      ['#filter_month', '#filter_year', '#filter_date'].forEach(function (field) {
        $(field).hide()
        $(field).parent().parent().find('label').hide()
      });
      $.ajax({
        url: '/common/report',
        data: { list_path: ['audit', category_value, kind_value], list_field: 'filter'}
      }).done(function (response) {
        response.forEach(function (name_kind) {
          $('#filter_' + name_kind[1]).show()
          $('#filter_' + name_kind[1]).parent().parent().find('label').show()
        });
      });
    }
  });
});
