$(document).on("turbolinks:load", function () {
  $.$behavior.to(".customers-profiles-edit", function () {
    const $kind = $("#customer_profile_attributes_kind");
    const $profile = $("#customer_profile_attributes_profile");
    const $profileAccessor = $('#customer_profile_attributes_profile_accessor');
    const $persona = $("#customer_profile_attributes_persona");
    const $personaAccessor = $('#customer_profile_attributes_persona_accessor');

    $(document).ready(function () {
      updateProfileField($kind.val());
    });

    $kind.on("change", function () {
      updateProfileField(this.value);
    });

    $profile.on("change", function () {
      updatePersonaField(this.value);
    });

    function updateProfileField(kind_value) {
      $.ajax({
        url: '/common/profile',
        data: { list_value: kind_value, list_field: 'profiles' }
      }).done(function (response) {
        if (response.length === 0) {
          $profile.val('')
          $profile.parent().parent().hide()
        } else {
          $profile.parent().parent().show()
          var output = '<option value=></option>';
          $profile.empty().append(function () {
            response.forEach(function (name_value) {
              output += '<option value=' + name_value[1] + '>' + name_value[0] + '</option>'
            });
            return output;
          });

          var kinds = response.map(function (name_value) {
            return name_value[1]
          });
          if($.inArray($profileAccessor.val(), kinds) != -1) {
            $profile.val($profileAccessor.val());
          }
        }
        updatePersonaField($profile.val());
      });
    }

    function updatePersonaField(profile_value) {
      $.ajax({
        url: '/common/profile',
        data: { list_value: profile_value, list_field: 'personas' }
      }).done(function (response) {
        if (response.length === 0) {
          $persona.val('')
          $persona.parent().parent().hide()
        } else {
          $persona.parent().parent().show()
          var output = '<option value=></option>';
          $persona.empty().append(function () {
            response.forEach(function (name_value) {
              output += '<option value=' + name_value[1] + '>' + name_value[0] + '</option>'
            });
            return output;
          });

          var kinds = response.map(function (name_value) {
            return name_value[1]
          });
          if($.inArray($personaAccessor.val(), kinds) != -1) {
            $persona.val($personaAccessor.val());
          }
        }
      });
    }
  });
});
