(function ($) {
  var attrSelector, dataSelector, $Behavior,
      find, findAll, toSelector, fetchStrategyArg, handleEventAttachmentAs,
      ATTR_REF = 'data-behavior', ATTR_EQUALS = '=', ATTR_CONTAINS_WORD = '~=';

  attrSelector = function(str) { return '[' + str + ']' }

  dataSelector = function(name, operator) {
    return attrSelector(ATTR_REF + operator + '"' + name +'"');
  };

  $Behavior = (function($) {
    var jQuerySelector, jQueryEventHandler;

    function Behavior(fn) {
      this._fn = fn;
    };

    jQuerySelector = function(selector) {
      return $.proxy(function() {
        var $context = $(selector);

        if ($context.length) this._fn.call($context, selector)
      }, this);
    };

    jQueryEventHandler = function(type, operator) {
      return function() {
        var args, $document = $(document);

        args = $.makeArray(arguments);
        args[1] = dataSelector(args[1], operator);
        args.push(this._fn);

        return $document[type].apply($document, args);
      };
    };

    Behavior.prototype = {
      constructor: Behavior,
      to: jQuerySelector,
      on: jQueryEventHandler('on', ATTR_CONTAINS_WORD),
      off: jQueryEventHandler('off', ATTR_CONTAINS_WORD),
      add: jQueryEventHandler('on', ATTR_EQUALS),
      remove: jQueryEventHandler('off', ATTR_EQUALS)
    };

    return Behavior
  })($);

  $Behavior.Error = function(msg) {
    this.name = '$.$behavior.Error';
    this.stack = (new Error()).stack;
    this.message = msg || 'Specify the context (selector) to apply the desired behavior.';
  };
  $Behavior.Error.prototype = new Error;

  find = function(name, operator) {
    return $(dataSelector(name, operator || ATTR_CONTAINS_WORD))
  };

  findAll = function() { return $(attrSelector(ATTR_REF)) };

  toSelector = function (selector, fn) {
    if (!selector) throw new $Behavior.Error();

    return $(new $Behavior(fn).to(selector));
  };

  fetchStrategyArg = function(strategy, args) {
    return function(name, index) {
      var error, value = args[index];

      if (value) return value;

      throw new $Behavior.Error(
        '$.$behavior.' + strategy + '()' + ' must receive the ' + name + ' as it ' + (index + 1) + 'º argument.'
      );
    }
  };

  handleEventAttachmentAs = function(strategy) {
    return function() {
      var instance,
          fetchArg = fetchStrategyArg(strategy, arguments),
          hasData = arguments.length === 4,
          args = [fetchArg('event', 0), fetchArg('target', 1)];

      if (hasData) args.push(arguments[2]);

      instance = new $Behavior(
        fetchArg('handler', hasData ? 3 : 2)
      );

      instance[strategy].apply(instance, args)
    }
  };

  $.$behavior = function(name, fn) {
    return toSelector(dataSelector(name, ATTR_CONTAINS_WORD), fn)
  };
  $.$behavior.selector = function(name) { return dataSelector(name, ATTR_CONTAINS_WORD) };
  $.$behavior.find = find;
  $.$behavior.findAll = findAll;
  $.$behavior.to = toSelector;
  $.$behavior.on = handleEventAttachmentAs('on');
  $.$behavior.off = handleEventAttachmentAs('off');
  $.$behavior.add = handleEventAttachmentAs('add');
  $.$behavior.remove = handleEventAttachmentAs('remove');
})(jQuery);
