/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// Importa todas as imagens para dentro desse pack
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)


/////
// Pack com os assets padrões criados pelo Rails para os controllers
// O setup básico de assets (frontend) da app rails fica aqui
///////

// These polyfills ensure that modern JS features (promises, async/await, arrow function
// expressions, and more) are available on the browsers you intend to support
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Bootstrap 3.4.1
// import 'bootstrap-sass/assets/javascripts/bootstrap'

// Bootstrap 4.5
// import 'bootstrap/dist/js/bootstrap'

// JQuery UI
// require('jquery-ui')

// Adiciona plugins externos
require('datatables.net')
require('datatables.net-dt')
require('jquery-validation')
require('jquery-maskmoney/dist/jquery.maskMoney')
require('bootstrap-datepicker/dist/js/bootstrap-datepicker')
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.pt-BR')
require('jquery-minicolors')
require('select2')
require('bootstrap-duallistbox/dist/jquery.bootstrap-duallistbox')
require('bootstrap-markdown/js/bootstrap-markdown')
import 'cocoon-js'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'select2/dist/css/select2.min.css'
import 'jquery-mask-plugin'

// Pace é incompatível com Rails UJS: https://github.com/HubSpot/pace/issues/476
// require('imports-loader?define=>true!pace-js/pace')

// Quill powerful rich text editor
// https://www.kohrvid.com/blog/posts/using-quilljs-with-rails-6
// https://github.com/quilljs/awesome-quill
import Quill from 'quill'
window.Quill = Quill
import 'quill/dist/quill.snow.css'

// Adiciona todos os arquivos JS criados para os controllers rails
import '../javascript/application'
import '../javascript/application/libs/jquery.behavior'

// // Adiciona todos os stylesheets
require("../stylesheet/application")
require("../stylesheet/heimdall")

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("turbolinks").start()

// Inicia ActiveStorage and ActionCable
// require("channels")
