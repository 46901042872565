$(document).on("turbolinks:load", function () {
  $.$behavior.to(".crm-plans-new, .crm-plans-create, .crm-plans-edit, .crm-plans-update, .crm-plans-replace", function () {
    const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

    $(document).ready(function () {
      updateLicenses();
    });

    $(document).on('change', '[id^=plan_licenses_attributes_][id$=_product_id]', function () {
      fetchLicenseProduct(this);
    });

    $(document).on('change', '[id^=plan_licenses_attributes_][id$=_custom]', function () {
      toggleLicenseFields(this);
    });

    $(document).on('change', function () {
      updateLicenses();

      $('.mask_money').each(function() {
        $(this).maskMoney({
          allowZero: true,
          prefix: "R$ ",
          decimal: ",",
          thousands: "."
        }).maskMoney('mask');
      });
    });

    function updateLicenses() {
      $('[id^=plan_licenses_attributes_][id$=_product_id]').each(function(){
        fetchLicenseProduct(this);
      });
      $('[id^=plan_licenses_attributes_][id$=_custom]').each(function(){
        toggleLicenseFields(this);
      });
    }

    function fetchLicenseProduct(obj) {
      let productId = $(obj).val();
      let licenseId = $(obj).attr('id').replace('plan_licenses_attributes_', '').replace('_product_id', '');
      $.ajax({
        url: '/common/product',
        data: { product_id: productId }
      }).done(function (response) {
        updateLicenseFields(licenseId, response);
      });
    }

    function updateLicenseFields(licenseId, response) {
      var licenseCustom = $('#plan_licenses_attributes_' + licenseId + '_custom').is(':checked');
      var licensePrice = $('#plan_licenses_attributes_' + licenseId + '_price');
      var licenseHardFranchise = $('#plan_licenses_attributes_' + licenseId + '_hard_franchise');
      var licenseFranchiseUsersLimit = $('#plan_licenses_attributes_' + licenseId + '_franchise_limit');
      var licenseFranchiseSurplusValue = $('#plan_licenses_attributes_' + licenseId + '_franchise_surplus_value');

      if (response && !licenseCustom) {
        var price = response['price'];
        var hardFranchise = response['hard_franchise'];
        var franchiseUsersLimit = response['franchise_limit'];
        var franchiseSurplusValue = response['franchise_surplus_value'];
      } else if (licensePrice.val()
                || licenseFranchiseUsersLimit.val()
                || licenseFranchiseSurplusValue.val()
                || licenseHardFranchise.val()) {
        var price = licensePrice.val().replace(/[^0-9]+/g,"");
        var hardFranchise = licenseHardFranchise.is(':checked');
        var franchiseUsersLimit = licenseFranchiseUsersLimit.val();
        var franchiseSurplusValue = licenseFranchiseSurplusValue.val().replace(/[^0-9]+/g,"");
      } else {
        var price = 0;
        var hardFranchise = false;
        var franchiseUsersLimit = 0;
        var franchiseSurplusValue = null;
      }
      licensePrice.val(formatter.format((price / 100).toFixed(2)));
      licenseHardFranchise.prop('checked', hardFranchise);
      licenseFranchiseUsersLimit.val(franchiseUsersLimit);
      if (franchiseSurplusValue) {
        licenseFranchiseSurplusValue.val(formatter.format((franchiseSurplusValue / 100).toFixed(2)));
      } else {
        licenseFranchiseSurplusValue.val(null);
      }
    }

    function toggleLicenseFields(obj) {
      let licenseId = $(obj).attr('id').replace('plan_licenses_attributes_', '').replace('_custom', '');
      let licenseCustom = $('#plan_licenses_attributes_' + licenseId + '_custom').is(':checked');
      $('#plan_licenses_attributes_' + licenseId + '_price').prop('disabled', !licenseCustom);
      $('#plan_licenses_attributes_' + licenseId + '_hard_franchise').prop('disabled', !licenseCustom);
      $('#plan_licenses_attributes_' + licenseId + '_franchise_limit').prop('disabled', !licenseCustom);
      $('#plan_licenses_attributes_' + licenseId + '_franchise_surplus_value').prop('disabled', !licenseCustom);

      if (licenseCustom) {
        fetchLicenseProduct($('#plan_licenses_attributes_' + licenseId + '_product_id'));
      }
    }
  });
});
