$(document).on("turbolinks:load", function () {
  function loadStatus() {
    $('.iframe-metabase').hide();
    $('.iframe-metabase').on('load', function () {
      $('.lds-ring').hide();
      $('.iframe-metabase').show();
    });
  }

  $.$behavior.to('.dashboard-general-index', loadStatus());
  $.$behavior.to('.dashboard-financial-index', loadStatus());
  $.$behavior.to('.dashboard-transactional-index', loadStatus());
  $.$behavior.to('.dashboard-sparkpay-index', loadStatus());
  $.$behavior.to('.dashboard-cohort-index', loadStatus());
  $.$behavior.to('.dashboard-customers-index', loadStatus());
});
