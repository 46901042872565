$(document).on("turbolinks:load", function () {
  $.$behavior.to("#contact_filter_type", function () {
    const $inputGeneric = $("#contact_attributes");
    const $inputType = $("#contact_filter_type");

    $inputType.on("change", function () {
      const val = $(this).val();
      const placeHolder = $(this).find("option:selected").text();

      $inputGeneric.attr("placeholder", placeHolder);
      $inputGeneric.attr("name", `filter[${val}]`);
    });

    function queryObj() {
      var result = {}, keyValuePairs = location.search.slice(1).split("&");
      keyValuePairs.forEach(function(keyValuePair) {
          keyValuePair = keyValuePair.split('=');
          result[decodeURIComponent(keyValuePair[0])] = decodeURIComponent(keyValuePair[1]) || '';
      });
      return result;
    }

    var valueFiltred = queryObj();
    setGenericInput();
    function setGenericInput() {
      const optionsAvaibles = ["filter[name]","filter[email]"]
      optionsAvaibles.map(function(index) {
        if(valueFiltred.hasOwnProperty(index) && valueFiltred[index]){
          $inputGeneric.attr("name", `${index}`);
          $inputGeneric.val(valueFiltred[index].replace("+", " "));
          res = index.match(/(?<=\[)[^[\]]+(?=\])/g);
          $inputType.val(res[0])
        }
      });

      if(!$inputGeneric.val()) {
        $inputType.val('name')
        $inputGeneric.attr("name", "filter[name]");
        $inputGeneric.attr("placeholder", "Nome");
      }
    }
  });
});
