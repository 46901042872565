$(document).on("turbolinks:load", function() {
  $.$behavior.to('.markdown-form', function () {
    var inputs = $('[id^=input-markdown]');
    inputs.each(loadQuill);

    $('.simple_form').each(function (index, item) {
      item.onsubmit = function () {
        inputs.each(function (index, item) {
          var quill = new Quill(item);
          var content = $(item).closest('.form-group').find('.rich-text-input')[0];
          content.value = quill.root.innerHTML;
        });
      }
    });

    function loadQuill(index, item) {
      quill = new Quill(item, {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1'}, { indent: '+1' }],
            ['link', 'clean']
          ]
        },
        theme: 'snow'
      });

      var content = $(quill.container).closest('.form-group').find('.rich-text-input')[0];
      quill.root.innerHTML = content.value;
    }

    quill.on('text-change', update);
    update();

    function update(delta) {
      $('.content-term').html(quill.root.innerHTML);
    }
  });
});
