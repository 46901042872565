/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/

$(document).on("turbolinks:load", function() {
  function updateProportionalCharged() {
    $('#billing_proportional_charged').prop('disabled', $('#billing_kind').val() != 'onboarding');
  }

  // Enable/disable on load
  updateProportionalCharged();

  $('#billing_kind').change(function(){
    updateProportionalCharged();
  });
});
