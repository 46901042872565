$(document).on("turbolinks:load", function () {
  $.$behavior.to(".financial-inspection-index", function () {
    const $kind = $("#filter_kind");

    $(document).ready(function () {
      updateFilterFields($kind.val());
    });

    $kind.on("change", function () {
      updateFilterFields(this.value);
    });

    function updateFilterFields(kind_value) {
      [
        '#filter_month', '#filter_start_month', '#filter_end_month', '#filter_months_duration',
        '#filter_payment_method', '#filter_plans', '#filter_date',
        '#filter_gateway', '#filter_statuses', '#filter_mrr_status'
      ].forEach(function (field) {
        $(field).closest('.form-group').hide()
      });
      $.ajax({
        url: '/common/report',
        data: { list_path: ['inspect', kind_value], list_field: 'filter'}
      }).done(function (response) {
        response.forEach(function (name_kind) {
          $('#filter_' + name_kind[1]).closest('.form-group').show()
        });
      });
    }
  });
});
